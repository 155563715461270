"use strict";
import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, renderSlot as _renderSlot, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
import { Status } from "@injectivelabs/utils";
export default /* @__PURE__ */ _defineComponent({
  __name: "CancelButton",
  props: {
    sm: Boolean,
    status: {
      type: Object,
      default: () => new Status()
    }
  },
  emits: ["click"],
  setup(__props, { emit }) {
    const props = __props;
    function handleClick() {
      if (props.status.isLoading()) {
        return;
      }
      emit("click");
    }
    return (_ctx, _cache) => {
      const _component_AppSpinner = _resolveComponent("AppSpinner");
      const _component_BaseIcon = _resolveComponent("BaseIcon");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["flex justify-end items-center", { "h-5 w-5 min-h-5 min-w-6": __props.sm, "h-6 w-6 min-h-6 min-w-6": !__props.sm }]),
        onClick: handleClick
      }, [
        __props.status.isLoading() ? (_openBlock(), _createBlock(_component_AppSpinner, {
          key: 0,
          sm: "",
          white: ""
        })) : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["cursor-pointer flex items-center justify-center rounded-full bg-opacity-10 hover:bg-opacity-30 hover:text-red-600 bg-red-500 text-red-500", { "min-w-5 w-5 h-5": __props.sm, "min-w-6 h-6 w-6": !__props.sm }])
        }, [
          _renderSlot(_ctx.$slots, "icon", {}, () => [
            _createVNode(_component_BaseIcon, {
              name: "bin",
              sm: ""
            })
          ])
        ], 2))
      ], 2);
    };
  }
});
